


import GetStaticProps from "/lib/CSRshop/getStaticProps";
import GetStaticPaths from '/lib/CSRshop/getStaticPaths'

import dynamic from "next/dynamic";
import { PrpProvider } from "/contexts/prpContext";
import useProductResultPage from "/hooks/useProductResultPage";



const ProductResultPage = dynamic(() =>
  import("/layout/productResultPage")
);

export async function getStaticProps({ params }) {
  return await GetStaticProps(params)
}

export async function getStaticPaths({ params }) {
  return await GetStaticPaths(params)
}

const Shop = ({ allShopPageProps }) => {

  return (
    <>

      <PrpProvider initialState={allShopPageProps} initialParams={{}}>
        <LayoutDiscriminator />
      </PrpProvider>
    </>
  )
}

const LayoutDiscriminator = () => {
  const isFacetResultPage = useProductResultPage(x => x.isFacetResultPage)
  const layout = <ProductResultPage isFrp={isFacetResultPage} />

  return layout
}



export default Shop
